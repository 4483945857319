import { addAttributesList, addOtherGroup, useAttributeGroupsList } from '@entities/attributes'
import { type AttributeGroup } from '@entities/attributes/types'
import { useSkuApi } from '@entities/catalog'
import { ROUTES, SKU } from '@shared/config'
import { useTranslate } from '@shared/ui/translate'
import { useTypedParams } from 'react-router-typesafe-routes'

const useFeaturedGroupsList = () => {
  const { id: skuId } = useTypedParams(ROUTES.CATALOG.SKU)
  const skuQuery = useSkuApi(skuId)
  const attributeGroupsQuery = useAttributeGroupsList()
  const translate = useTranslate(['label.fullness', 'label.progress', 'label.other'])

  const featuredAttributes = skuQuery.data?.attributes.filter((attribute) => {
    return attribute.is_featured === true
  })

  // Добавляем прогресс бар и ставим на первую позицию
  const progressGroup = {
    id: 0,
    name: translate['label.fullness'],
    order: 0,
    attributes: [
      {
        id: 0,
        name: translate['label.progress'],
        value: skuQuery.data?.progress.toString(),
        slug: SKU.PROGRESS,
      },
    ],
  } as AttributeGroup

  if (attributeGroupsQuery.isSuccess && featuredAttributes) {
    // Create initial groups list and filter empty groups
    let attributesGroupsList: AttributeGroup[] = addAttributesList({
      groups: attributeGroupsQuery.data,
      attributes: featuredAttributes,
    }).filter((group): group is AttributeGroup => {
      return group.attributes.length > 0
    })

    // Добавляем прогресс
    attributesGroupsList = [...attributesGroupsList, progressGroup]

    // Добавляем группу "Прочее"
    return addOtherGroup(attributesGroupsList, featuredAttributes, translate['label.other'])
  }

  return []
}

export { useFeaturedGroupsList }
