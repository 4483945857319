import {
  API_OBJECT_GROUP,
  ATTRIBUTE_FLAGS,
  extractAttribute,
  useObjectGroupListApi,
} from '@shared/api'
import { ROUTES } from '@shared/config'
import { useTranslate } from '@shared/ui/translate'
import { useMemo } from 'react'

import { type AttributeGroup } from '../types'
import { type UseAttributeGroupsListProps } from './types'

export const useAttributeGroupsList = (queryArgs: UseAttributeGroupsListProps = {}) => {
  const { data: attributeGroupsData, ...attributeGroupsQuery } = useObjectGroupListApi({
    type: API_OBJECT_GROUP.ATTRIBUTE,
    ordering: 'order',
    ...queryArgs,
  })

  const translate = useTranslate(['message.empty_name'])

  // Используем useMemo для оптимизации вычислений
  const data = useMemo(() => {
    if (!attributeGroupsData) return []

    const groupsMap = new Map<number, AttributeGroup>()

    // Создаем базовую структуру групп
    attributeGroupsData.results.forEach((group) => {
      const name =
        extractAttribute(group, ATTRIBUTE_FLAGS.NAME)?.value ?? translate['message.empty_name']

      groupsMap.set(group.id, {
        ...group,
        href: ROUTES.SETTINGS.ATTRIBUTE_GROUPS.GROUP.$buildPath({ params: { id: group.id } }),
        name,
        attributesIds: [],
      })
    })

    // Сохраняем исходный порядок из results
    return attributeGroupsData.results
      .map((group) => {
        return groupsMap.get(group.id)!
      })
      .filter(Boolean)
  }, [attributeGroupsData])

  return {
    data,
    count: attributeGroupsData?.count,
    ...attributeGroupsQuery,
  }
}
