import {
  type AttributeFieldNames,
  type AutomaticTaskFieldNames,
  type CategoryFieldNames,
  type TranslatedNames,
  type ValidatorFieldNames,
} from './types'

export const DEFAULT_LANGUAGE = 'en'

export const APP_NAME = 'SKU HUB'
// TODO: Ссылка на публичный каталог, скорее всего будет в ENV
export const APP_PUBLIC_URL = 'https://google.com/search?q='

export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT_FULL = 'YYYY-MM-DD HH:MM:SS'
export const DATE_RANGE_LABEL = 'date-range'

export const SIMPLE_LAYOUT_CONTENT_WIDTH = 268
export const SIMPLE_LAYOUT_LOGO_WIDTH = 210

export const SIDEBAR_OPEN_MIN_WIDTH = 200
export const SIDEBAR_CLOSED_MIN_WIDTH = 59
export const SIDEBAR_OPEN_LOGO_WIDTH = 118
export const SIDEBAR_CLOSED_LOGO_WIDTH = 21

export const ELEMENTS_XXS_WIDTH = 160
export const ELEMENTS_XS_WIDTH = 240
export const ELEMENTS_S_WIDTH = 300
export const ELEMENTS_M_WIDTH = 400
export const ELEMENTS_L_WIDTH = 750

export const ELEMENTS_M_HEIGHT = 400

export const CATALOG_CARD_SKELETON_AMOUNT = 9
export const CATALOG_CARD_IMAGE_SIZE = 200
export const CATALOG_TABLE_IMAGE_SIZE = 50

export const DEFAULT_ITEMS_PER_PAGE = 25
export const ITEMS_PER_PAGE_OPTIONS = [DEFAULT_ITEMS_PER_PAGE, 50, 100]

export const OTHER_ATTRIBUTE_GROUP_ID = -1

export enum CATALOG_VIEW {
  CARDS = 'catalog_view.cards',
  TABLE = 'catalog_view.table',
}

export enum ATTRIBUTE_LABEL {
  BRAND = 'Brand',
  NAME = 'PN',
  PREVIEW = 'Preview',
  SKU = 'SKU',
}

export enum ATTRIBUTE_FIELDS {
  UUID = 'uuid',
  IS_ENABLED = 'is_enabled',
  IS_DELETED = 'is_deleted',
  NAME = 'name',
  ATTRIBUTE_TYPE = 'attribute_type',
  CATEGORY = 'category',
  IS_FEATURED = 'is_featured',
  IS_REQUIRED = 'is_required',
  DESCRIPTION = 'description',
  IS_PUBLIC = 'is_public',
  OBJECT_GROUP = 'object_group',
  IS_SKU = 'is_sku',
  IS_NAME = 'is_name',
  IS_BRAND = 'is_brand',
  IS_PREVIEW = 'is_preview',
  SLUG = 'slug',
  UPDATED_AT = 'updated_at',
  CREATED_AT = 'created_at',
  CREATED_BY = 'created_by',
}

export const ATTRIBUTE_FIELD_NAMES: AttributeFieldNames = {
  uuid: 'field.uuid',
  is_enabled: 'field.is_enabled',
  is_deleted: 'field.is_deleted',
  name: 'field.name',
  attribute_type: 'field.task_type',
  category: 'field.category',
  is_featured: 'field.is_featured',
  is_required: 'field.is_required',
  description: 'field.description',
  is_public: 'field.is_public',
  object_group: 'field.group',
  is_sku: 'field.is_sku',
  is_name: 'field.is_name',
  is_brand: 'field.is_brand',
  is_preview: 'field.is_preview',
  slug: 'field.slug',
  updated_at: 'field.updated_at',
  created_at: 'field.created_at',
  created_by: 'field.created_by',
}

export enum VALIDATOR_FIELDS {
  UUID = 'uuid',
  IS_ENABLED = 'is_enabled',
  IS_DELETED = 'is_deleted',
  IS_REQUIRED_VALUE = 'is_required_value',
  USE_VALIDATORS = 'use_validators',
  VALIDATORS_ATTRIBUTE_NAME = 'validated_attribute_name',
  WEIGHT = 'weight',
  BLOCK_SAVE_WITH_VALIDATION_ERRORS = 'block_save_with_validation_errors',
  MIN_LENGTH = 'min_length',
  MAX_LENGTH = 'max_length',
  MIN_NUMERIC_VALUE = 'min_numeric_value',
  MAX_NUMERIC_VALUE = 'max_numeric_value',
  FORBIDDEN_WORDS = 'forbidden_words',
  VALIDATED_ATTRIBUTE = 'validated_attribute',
  GROUP = 'group',
  UPDATED_AT = 'updated_at',
  CREATED_AT = 'created_at',
  CREATED_BY = 'created_by',
  ID = 'id',
}

export const VALIDATOR_FIELD_NAMES: ValidatorFieldNames = {
  uuid: 'field.uuid',
  is_enabled: 'field.is_enabled',
  is_deleted: 'field.is_deleted',
  use_validators: 'field.use_validators',
  validated_attribute_name: 'field.validated_attribute',
  is_required_value: 'field.is_required_value',
  block_save_with_validation_errors: 'field.block_save_with_validation_errors',
  group: 'field.group',
  updated_at: 'field.updated_at',
  created_at: 'field.created_at',
  created_by: 'field.created_by',
  weight: 'field.weight',
  min_length: 'field.min_length',
  max_length: 'field.min_length',
  min_numeric_value: 'field.min_numeric_value',
  max_numeric_value: 'field.max_numeric_value',
  forbidden_words: 'field.forbidden_words',
  validated_attribute: 'field.validated_attribute',
  id: 'field.id',
}

export enum CATEGORY_FIELDS {
  ID = 'id',
  SKUS_COUNT = 'skus_count',
  ATTRIBUTES_COUNT = 'attributes_count',
  CREATED_BY = 'created_by',
  UUID = 'uuid',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  IS_ENABLED = 'is_enabled',
  IS_DELETED = 'is_deleted',
  GROUP = 'group',
  PARENT = 'parent',
  OBJECT_GROUP = 'object_group',
}

export const CATEGORY_FIELD_NAMES: CategoryFieldNames = {
  id: 'field.id',
  skus_count: 'field.skus_count',
  attributes_count: 'field.attributes_count',
  created_by: 'field.created_by',
  uuid: 'field.uuid',
  created_at: 'field.created_at',
  updated_at: 'field.updated_at',
  is_enabled: 'field.is_enabled',
  is_deleted: 'field.is_deleted',
  group: 'field.group',
  parent: 'field.parent',
  object_group: 'field.catalogs',
}

export enum AUTOMATIC_TASK_FIELDS {
  IS_ENABLED = 'is_enabled',
  GROUP = 'group',
  NAME = 'name',
  STATUS = 'status',
  TASK_TYPE = 'task_type',
  EMAIL_LIST = 'email_list',
  IS_SEND_NOTIFICATIONS = 'is_send_notifications',
  TRIGGER = 'trigger',
  START_AT = 'start_at',
  CRONTAB_SCHEDULE = 'crontab_schedule',
  TASK_RULES = 'task_rules',
  UUID = 'uuid',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  CREATED_BY = 'created_by',
  LAST_LAUNCH_AT = 'last_launch_at',
}

export const AUTOMATIC_TASK_FIELDS_NAMES: AutomaticTaskFieldNames = {
  uuid: 'field.uuid',
  is_enabled: 'field.is_enabled',
  is_send_notifications: 'field.is_send_notifications',
  created_at: 'field.created_at',
  created_by: 'field.created_by',
  crontab_schedule: 'field.crontab_schedule',
  email_list: 'field.email_list',
  group: 'field.group',
  last_launch_at: 'field.last_launch_at',
  name: 'field.name',
  start_at: 'field.start_at',
  status: 'field.status',
  task_rules: 'field.task_rules',
  task_type: 'field.task_type',
  trigger: 'field.trigger',
  updated_at: 'field.updated_at',
}

export enum PARAMS {
  AFTER = '_after',
  ATTRIBUTE_AFTER = '__after',
  ATTRIBUTE_BEFORE = '__before',
  ATTRIBUTE_TYPE = 'attribute_type',
  ATTRIBUTE_VALUE = 'attribute_value',
  ATTRIBUTE_VALUE_CONTAIN = '__contain',
  ATTRIBUTE_VALUE_EQUAL = '__equal',
  ATTRIBUTE_VALUE_ISNULL = '__isnull',
  ATTRIBUTE_VALUE_NOT_CONTAIN = '__not_contain',
  ATTRIBUTE_VALUE_NOT_EQUAL = '__not_equal',
  BEFORE = '_before',
  CATEGORIES = 'categories',
  CATEGORY = 'category',
  CATEGORY_ISNULL = 'category__isnull',
  FILTERS = 'filters',
  MODEL = 'model',
  OBJECT_GROUP = 'object_group',
  OBJECT_GROUPS = 'object_groups',
  OBJECT_GROUP_ISNULL = 'object_group__isnull',
  ORDERING = 'ordering',
  ORDER_BY = 'order_by',
  PAGE = 'page',
  PROGRESS_MAX = 'progress_max',
  PROGRESS_MIN = 'progress_min',
  PUBLIC = 'is_public',
  SEARCH = 'search',
  SIZE = 'size',
  IS_NAME = 'is_name',
  IS_SKU = 'is_sku',
}

// Параметры, которые нужно проверять для фильтрации в каталоге
export const SKU_FILTERS_PARAMS = [
  PARAMS.AFTER,
  PARAMS.ATTRIBUTE_VALUE_CONTAIN,
  PARAMS.ATTRIBUTE_VALUE_EQUAL,
  PARAMS.ATTRIBUTE_VALUE_ISNULL,
  PARAMS.ATTRIBUTE_VALUE_NOT_CONTAIN,
  PARAMS.ATTRIBUTE_VALUE_NOT_EQUAL,
  PARAMS.BEFORE,
  PARAMS.PROGRESS_MAX,
  PARAMS.PROGRESS_MIN,
  PARAMS.PUBLIC,
]

export enum FILE_UPLOAD_ALLOWED {
  IMAGE = '.jpg, .jpeg, .png',
  DOCUMENTS = '.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .txt, .ppt, .pptx, .mp4, .avi, .mov, .wmv, .mkv, .zip, .rar, .csv, .xml, .json',
}

export enum SKU {
  PROGRESS = 'progress',
  PUBLIC = 'public',
}

export enum TASK_STATUS {
  PLANNED = 10,
  IN_PROGRESS = 20,
}

export const TASK_STATUS_NAME: TranslatedNames = {
  [TASK_STATUS.PLANNED]: 'task_status.planned',
  [TASK_STATUS.IN_PROGRESS]: 'task_status.in_progress',
}

export enum AUTOMATIC_TASK_TYPE {
  EXPORT_SKU = 10,
  IMPORT_SKU = 20,
}

export const AUTOMATIC_TASK_NAME: TranslatedNames = {
  [AUTOMATIC_TASK_TYPE.EXPORT_SKU]: 'automatic_task_name.export_sku',
  [AUTOMATIC_TASK_TYPE.IMPORT_SKU]: 'automatic_task_name.import_sku',
}

export enum IMPORT_STATUS {
  LOADED = 10,
  VALIDATED = 20,
  IMPORTED = 30,
  PLANNED = 40,
  VALIDATION_ERROR = 50,
  IMPORT_ERROR = 60,
}

export enum VALUE_FILTER {
  FILLED,
  UNFILLED,
}

export const VALUE_FILTER_NAME: TranslatedNames = {
  [VALUE_FILTER.FILLED]: 'value_filter.filled',
  [VALUE_FILTER.UNFILLED]: 'value_filter.unfilled',
}

export enum IMPORT_TYPE {
  SKU = 10,
}

export enum CRON_FIELD_NAME {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY_OF_MONTH = 'day_of_month',
  MONTH_OF_YEAR = 'month_of_year',
  DAY_OF_WEEK = 'day_of_week',
}
